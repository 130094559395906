
import { reactive, ref, onMounted, getCurrentInstance } from 'vue'
import AxiosAdapter from '../request/AxiosAdapter'
import { throwCustomFunction } from '../commons/ErrorHandle'
import { useRoute } from 'vue-router'
import GetImageConversion from '../commons/ImageConversion'
import { acceptImage } from '../commons/unit'
export default {
  name: 'UserType',
  props: {
    // institutionId: String,
    userType: Number,
    proxyType: Number
  },
  setup(props: any, context: any) {
    const proxy: any = getCurrentInstance()
    const institutionId = ref()
    const acceptImageUrl = ref(acceptImage)
    const route = useRoute()
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const identityPhotoUrl: any[] = []
    const otherUrl: any[] = []
    const frontCardUrl: any[] = []
    const backCardUrl: any[] = []

    const getUrl = process.env.VUE_APP_BASE_API
    const urlsList = reactive({
      identityPhotoUrl: identityPhotoUrl, //本人自然人 当事人身份证明
      otherUrl: otherUrl, // 本人背面
      frontCardUrl: frontCardUrl, //身份证正面照片
      backCardUrl: backCardUrl //身份证背面照片
    })
    //校验规则
    const rules = reactive({
      frontCardUrl: [
        { required: true, message: '请上传身份证正面图片', trigger: 'change' }
      ],
      backCardUrl: [
        { required: true, message: '请上传身份证背面图片', trigger: 'change' }
      ],
      identityPhotoUrl: [
        { required: true, message: '请上传手持分身证图片', trigger: 'change' }
      ]
    })
    onMounted(() => {
      if (route.query.institutionId) {
        institutionId.value = route.query.institutionId
      }
    })
    // const successUpload = (type: any, res?: any) => {
    // const url = res && res.value
    // const list: any = urlsList[type] as any
    // list.push(url)
    // }
    //身份证正面成功的上传回调
    const frontCardSuccess = (res: any) => {
      // successUpload('caseUser', res)
      const url = res && res.value
      if (url != '') {
        proxy.proxy.$refs.ruleFormRef.clearValidate('frontCardUrl')
        urlsList.frontCardUrl.push(url)
      }
    }
    //身份证背面上传成功的回调
    const backCardSuccess = (res: any) => {
      // console.log(res)
      const url = res && res.value
      if (url != '') {
        proxy.proxy.$refs.ruleFormRef.clearValidate('backCardUrl')
        urlsList.backCardUrl.push(url)
      }
    }
    //手持身份证成功的上传回调
    const userHandleSuccess = (res: any) => {
      // successUpload('caseUser', res)
      const url = res && res.value
      if (url != '') {
        proxy.proxy.$refs.ruleFormRef.clearValidate('identityPhotoUrl')
        urlsList.identityPhotoUrl.push(url)
      }
    }
    //手写地址确认书上传成功的回调
    const userBackHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.otherUrl.push(url)
    }
    //提交资料的回调
    const userUploadFun = async () => {
      const params: any = {
        identityPhotoUrl: '',
        otherUrl: '',
        identityPhotoBackUrl: '',
        institutionId: route.query.institutionId
      }
      params.identityPhotoBackUrl =
        urlsList.frontCardUrl + ',' + urlsList.backCardUrl
      params.identityPhotoUrl = urlsList.identityPhotoUrl.toString()
      params.otherUrl = urlsList.otherUrl.toString()

      const data: any = await AxiosAdapter.post({
        url: '/confirmAddress/commonAddPicture',
        data: params
      })
      context.emit('nextuser', data)
    }
    //判断校验是否成功
    const submitForm = () => {
      proxy.proxy.$refs.ruleFormRef.validate((valid: any, object: any) => {
        if (!valid) {
          console.log('校验未成功')
        } else {
          userUploadFun()
        }
      })
    }
    const userHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.identityPhotoUrl.indexOf(item)
      if (index !== -1) {
        urlsList.identityPhotoUrl.splice(index, 1)
      }
    }
    const userBackHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.otherUrl.indexOf(item)
      if (index !== -1) {
        urlsList.otherUrl.splice(index, 1)
      }
    }
    const getList = (urls?: any) => {
      if (!urls) return
      const arr: any[] = []
      urls.forEach((current: any) => {
        if (current) {
          arr.push({ url: current })
        }
      })
      return arr
    }

    //正面照片
    const getFrontCardUrl = (urls?: any) => {
      return getList(urls)
    }
    //背面照片
    const getBackCardUrl = (urls?: any) => {
      return getList(urls)
    }
    //手持照片
    const getUserList = (urls?: any) => {
      return getList(urls)
    }
    //手写地址确认书
    const getOtherList = (urls?: any) => {
      return getList(urls)
    }
    const beforeUpload = async (file: any) => {
      return new Promise((resolve) => {
        const data = GetImageConversion(file)
        return resolve(data)
      })
    }
    const handlePictureCardPreview = async (file: any) => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }

    const isHide = (arr: [], count: any) => {
      const bool = arr.length === count
      return bool
    }

    return {
      getUserList,
      getOtherList,
      getBackCardUrl,
      getFrontCardUrl,
      dialogImageUrl,
      dialogVisible,
      institutionId,
      getList,
      handlePictureCardPreview,
      userUploadFun,
      acceptImageUrl,
      userHandleSuccess,
      backCardSuccess,
      frontCardSuccess,
      userHandleRemove,
      urlsList,
      getUrl,
      beforeUpload,
      userBackHandleSuccess,
      userBackHandleRemove,
      isHide,
      rules,
      submitForm
    }
  }
}
